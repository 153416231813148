import React, {useContext} from "react";
import {AuthContext} from "../providers/auth/AuthContext";
import {Redirect} from "react-router";

function IndexPage() {
    const {authenticated, user} = useContext(AuthContext)

    if (authenticated) {
        return (
            <Redirect to="/pages" />
        )
    }
    return (
        <Redirect to="/login" />
    )
}

export default IndexPage
