import React, {useContext} from "react";
import {AuthContext} from "../providers/auth/AuthContext";
import {Redirect} from "react-router";

const Logout = () => {
    const {onLogout, logout} = useContext(AuthContext)

    logout()
    onLogout()
    return(
        <Redirect to="/" />
    )
}

export default Logout
