import React, {useContext, useState} from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { NavLink as RRNavLink, useLocation } from 'react-router-dom'
import {AuthContext} from "../../providers/auth/AuthContext"
import {faLock, faSignOutAlt, faUser} from "@fortawesome/free-solid-svg-icons"

const AppNavbar = () => {
    const {authenticated, user} = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)


    const toggle = () => setIsOpen(!isOpen)

    const currentLocation = useLocation()

    return (
            <Navbar color="dark" dark expand="md">
                <NavbarBrand tag={RRNavLink} to="/">MD Chat</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar className={"mr-auto"}>
                    {!authenticated && (
                        <NavItem>
                            <NavLink to="/login" tag={RRNavLink}>Login</NavLink>
                        </NavItem>
                    )}

                    {authenticated && (
                        <>
                            <NavItem>
                                <NavLink disabled={true} active={currentLocation.pathname === '/pages'} to="/pages" tag={RRNavLink}>📝 My Pages</NavLink>
                            </NavItem>
                        </>


                    )}
                    </Nav>
                    {authenticated && (
                        <Nav navbar className={"ml-auto"}>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    {user.username} <FontAwesomeIcon className="ml-1" icon={faUser} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem to="/password" tag={RRNavLink}>
                                        Change password <FontAwesomeIcon className="ml-1" icon={faLock} />
                                    </DropdownItem>
                                    <DropdownItem divider={true} />
                                    <DropdownItem to="/logout" tag={RRNavLink}>
                                        <b>Log out <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} /></b>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    )}
                </Collapse>

            </Navbar>
    )
}

export default AppNavbar
