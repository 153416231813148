let mdApiUrl = window._env.REACT_APP_MD_API_BASE_URL


if (!mdApiUrl.endsWith('/')) {
    mdApiUrl += '/'
}

const config = {
    url: {
        v1: {
            API_URL: mdApiUrl,
            API_URL_LOGIN: mdApiUrl + 'api/v1/auth/login',
            AUTH: {
                CHANGE_PASS: mdApiUrl + 'api/v1/auth/password'
            },
            PAGES: {
                GET_MANY: mdApiUrl + 'api/v1/pages/',
                CONNECT: mdApiUrl + 'api/v1/pages/connect',
                DISCONNECT: mdApiUrl + 'api/v1/pages/connect',
                UPDATE: mdApiUrl + 'api/v1/pages/update'
            },
        }
    }
}

export const apiConfig = config
