import {createContext} from 'react';

const noop = () => {};

const AuthContext = createContext({
    authenticated: false,
    login: noop,
    logout: noop,
    onLogin: noop,
    onLogout: noop,
    user: null,
});

export {
    AuthContext
}
