import React, {useMemo, useState} from 'react'
import { AuthContext } from './AuthContext'
import * as jwt_decode from 'jwt-decode'

import axios from "axios"
import {apiConfig} from "../../constants/api"

const FormData = require('form-data');

const login = (username, password) => {
    const data = new FormData()
    data.append('username', username)
    data.append('password', password)

    return axios
        .post(apiConfig.url.v1.API_URL_LOGIN, data)
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
                return {success: true, token: response.data.access_token};
            }
            return {success: false, msg: null};
        }).catch(e => {
            let msg = null
            if (e.response && e.response.data && e.response.data.message){
                msg = e.response.data.message
            }

            return {success: false, message: msg};
        })
}

const logout = () => {
    localStorage.removeItem('token');
}

const isAuthenticated = () => {
    const jwt = getJwt();
    if (!jwt) {
        return false;
    }

    const token = jwt_decode(jwt)
    const dateNow = new Date();
    return (token.exp * 1000) >= dateNow.getTime();

}

const getJwt = () => {
    return localStorage.getItem('token')
}

const getCurrentUser = () => {
    if (!isAuthenticated) {
        return null
    }

    const jwt = getJwt();
    if (!jwt) {
        return null;
    }
    const token = jwt_decode(jwt)
    const u = {username: token.username, roles: token.roles, isAdmin: token.is_superuser}
    return u
}

export const AuthProvider = ({children}) => {
    const [authenticated, setAuthenticated] = useState(isAuthenticated());
    const [user, setUser] = useState(getCurrentUser())

    const onLogin = () => {
        setUser(getCurrentUser())
        setAuthenticated(true)
    }

    const onLogout = () => {
        setAuthenticated(false)
        setUser(null)
    }

    const contextValue = useMemo(
        () => ({
            authenticated: authenticated,
            login: login,
            logout: logout,
            onLogin: onLogin,
            onLogout: onLogout,
            user: user,
        }),
        [authenticated, user]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};
