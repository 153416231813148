import React, {useContext, useState} from "react";
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    FormFeedback
} from 'reactstrap'


import {Redirect, useHistory} from "react-router";
import { AuthContext } from "../../providers/auth/AuthContext";

const DEFAULT_ERR_MSG = 'Unknown error'

const LoginPage = () => {
    const history = useHistory()

    const {authenticated, login, onLogin} = useContext(AuthContext);
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState(DEFAULT_ERR_MSG)

    const emailChanged = e => {
        setIsError(false)
        setEmail(e.target.value)
    }

    const passwordChanged = e => {
        setIsError(false)
        setPassword(e.target.value)
    }

    if (authenticated) {
        return <Redirect to="/" />;
    }

    const performLogin = () => {
        let data = login(email, password)
        data.then(r => {
            if (r.success) {
                onLogin()
                history.push('/')
                window.location.reload();
                return
            }
            const msg = r.message ?? DEFAULT_ERR_MSG
            setErrorMessage(msg)
            setIsError(true)
        })
    }

    const submitForm = e => {
        e.preventDefault()
        performLogin()
    }

    return (
            <Form onSubmit={submitForm}>
                <h3>Sign In</h3>

                <FormGroup role="form">
                    <Label>Email address</Label>
                    <Input invalid={isError} type="email" name="email" value={email} onChange={e => emailChanged(e)} />
                </FormGroup>

                <FormGroup role="form">
                    <Label>Password</Label>
                    <Input invalid={isError} type="password" name="password" value={password} onChange={e => passwordChanged(e)} />
                    { isError &&<FormFeedback>{errorMessage}</FormFeedback>}
                </FormGroup>

                <Button type="submit" color="primary" >Login</Button>
            </Form>
        );
}

export default LoginPage;
