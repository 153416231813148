import React, {useEffect, useState} from "react"

import API from "../../utils/API"
import { apiConfig } from "../../constants/api"

import Loader from 'react-loader-spinner'
import AdminLayout from "../../components/Admin/AdminLayout"
import { useToasts } from 'react-toast-notifications'
import {Row, Table} from "reactstrap"
import FacebookLogin from 'react-facebook-login'
import PageRow from "./PageRow";
import FbPageEditModal from "./modals/FbPageEditModal";


const getButtonText = (user) => {
    if (!user) {
        return "Login With Facebook"
    }
    return "Manage via Facebook"
}

const FbPagesPage = () => {
    const [pages, setPages] = useState([])
    // const [isFbLogged, setIsFbLogged] = useState(false)
    const [fbUser, setFbUser] = useState(undefined)

    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editingPage, setEditingPage] = useState(null)

    const {addToast} = useToasts()

    const openEditPageModal = (p) => {
        setIsEditing(true)
        setEditingPage(p)
    }

    const getPages = (token, user_id) => {
        API.get(apiConfig.url.v1.PAGES.GET_MANY, { params: { token, user_id } })
            .then((res) => {
                // console.log(res.data)
                setPages(res.data)
            }).catch((err) => {
                console.log(err)
                addToast('Error fetching pages', { appearance: 'error', autoDismiss: true})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onSave = () => {
        getPages(fbUser.accessToken, fbUser.userID)
    }

    const connectPage = (p) => {
        setIsLoading(true)
        API.post(apiConfig.url.v1.PAGES.CONNECT, { page_id: p.page_id })
            .then((res) => {
                addToast(`Page connected`, { appearance: 'success', autoDismiss: true})
            }).catch((err) => {
                console.log(err)
                addToast('Error connecting page. Try again', { appearance: 'error', autoDismiss: true})
        }).finally(() => {
            getPages(fbUser.accessToken, fbUser.userID)
        })
    }

    const disconnectPage = (p) => {
        setIsLoading(true)
        API.delete(apiConfig.url.v1.PAGES.DISCONNECT, { data: { page_id: p.page_id }})
            .then((res) => {
                addToast(`Page disconnected`, { appearance: 'success', autoDismiss: true})
            }).catch((err) => {
            console.log(err)
            addToast('Error disconnecting page. Try again', { appearance: 'error', autoDismiss: true})
        }).finally(() => {
            getPages(fbUser.accessToken, fbUser.userID)
        })
    }

    const responseFacebook = (r) => {
        console.log(r)
        if (r.accessToken && r.userID) {
            setIsLoading(true)
            setFbUser(r)
            getPages(r.accessToken, r.userID)
        }
    }

    if (isLoading) {
        return (
            <AdminLayout>
                <Row className="justify-content-center">
                    <Loader color="#00BFFF"/>
                </Row>
            </AdminLayout>
        )
    }


    return (
        <AdminLayout>
            <h2>Your pages list</h2>
            <FacebookLogin
                appId="1373778453139200"
                autoLoad={true}
                returnScopes={true}
                size="small"
                textButton={getButtonText(fbUser)}
                scope="pages_manage_metadata,pages_show_list,pages_messaging,pages_manage_engagement"
                // onClick={componentClicked}
                callback={responseFacebook} />

            <Table striped hover responsive className={"mt-2"}>
                <thead>
                <tr>
                    {/*<th>#</th>*/}
                    <th>Page Name</th>
                    <th>Status</th>
                    <th>Bot ID</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>

                {pages.map((p) => {
                    return <PageRow key={p.page_id} fbPage={p} connect={connectPage} disconnect={disconnectPage} edit={openEditPageModal} />
                })}
                </tbody>
            </Table>
            <FbPageEditModal isOpen={isEditing} setIsOpen={setIsEditing} pageToEdit={editingPage} onSave={onSave} />
        </AdminLayout>
    )
}

export default FbPagesPage
