import React from "react"
import {Button, ButtonGroup} from "reactstrap"

import {faTrash, faPlusCircle, faCog} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

// const getDate = (inp) => {
//     return new Date(inp).toLocaleDateString('en-US')
// }

const getConnectedText = (isConnected) => {
    if (isConnected) {
        return "Connected ✅"
    }

    return "Not Connected"
}

const getBotText = (bot, isConnected) => {
    if (!isConnected) {
        return "-"
    }

    if (bot) {
        return bot
    }

    return "❗️ No Bot Connected"
}

const PageRow = ({fbPage, disconnect, connect, edit}) => {
    const editPage = (p) => {
        if (edit) {
            return () => edit(p)
        }
        return () => {}
    }

    const disconnectPage = (p) => {
        if (disconnect) {
            return () => disconnect(p)
        }
        return () => {}
    }

    const connectPage = (p) => {
        if (connect) {
            return () => connect(p)
        }
        return () => {}
    }

    return (
        <tr key={fbPage.page_id}>
            <th scope="row">{fbPage.page_name}</th>
            <td>{getConnectedText(fbPage.is_connected)}</td>
            <td>{getBotText(fbPage.bot_id, fbPage.is_connected)}</td>
            <td>
                <ButtonGroup size="sm">
                    {fbPage.is_connected && (
                        <>
                        <Button onClick={disconnectPage(fbPage)} color="danger">Disconnect <FontAwesomeIcon icon={faTrash}/></Button>
                        <Button onClick={editPage(fbPage)} color="primary">Settings <FontAwesomeIcon icon={faCog}/></Button>
                        </>
                    )}
                    {!fbPage.is_connected && (
                        <Button onClick={connectPage(fbPage)} color="success">Connect <FontAwesomeIcon icon={faPlusCircle}/></Button>
                    )}
                    {/*<Button onClick={editClient(client)} color="primary"><FontAwesomeIcon icon={faUserEdit}/></Button>*/}
                </ButtonGroup>
            </td>
        </tr>
    )
}

export default PageRow
