import React from 'react';
import AppNavbar from "./components/AppNavbar/AppNavbar";
import AppRouter from "./components/AppRouter";
import { AuthProvider } from "./providers/auth/AuthProvider";
import { ToastProvider } from 'react-toast-notifications'


function App() {

    return (
        <AuthProvider>
                <ToastProvider>
                    <AppNavbar />
                    <AppRouter />
                </ToastProvider>
        </AuthProvider>
    )
}

export default App;
