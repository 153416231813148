import React, { useState } from "react"
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    FormFeedback, Row
} from 'reactstrap'


import AdminLayout from "../../components/Admin/AdminLayout"
import API from "../../utils/API"
import { apiConfig } from "../../constants/api"
import Loader from "react-loader-spinner"
import { useToasts } from "react-toast-notifications"

const DEFAULT_ERR_MSG = 'Unknown error'
const WRONG_OLD_PASS_ERR = 'Incorrect old password'
const PASSWORDS_ARE_NOT_SAME = 'Passwords do not match'
const MISSING_FIELDS = 'Some required fields are missing'

const ChangePasswordPage= () => {

    const [isLoading, setIsLoading] = useState(false)

    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(DEFAULT_ERR_MSG)

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')


    const {addToast} = useToasts()

    const resetForm = () => {
        setNewPassword('')
        setOldPassword('')
        setRepeatPassword('')
    }

    const oldPasswordChanged = (e) => {
        setIsError(false)
        setOldPassword(e.target.value)
    }

    const newPasswordChanged = (e) => {
        setIsError(false)
        setNewPassword(e.target.value)
    }

    const repeatPasswordChanged = (e) => {
        setIsError(false)
        setRepeatPassword(e.target.value)
    }


    const performPasswordChange = () => {
        setIsLoading(true)
        API.post(apiConfig.url.v1.AUTH.CHANGE_PASS, { old_password: oldPassword, new_password: newPassword })
            .then((resp) => {
                addToast('Password changed', { appearance: 'success', autoDismiss: true })
            }).catch((err) => {
            addToast('Error changing password', { appearance: 'error', autoDismiss: true })
        }).finally(() => {
            resetForm()
            setIsLoading(false)
        })
    }

    const submitForm = e => {
        e.preventDefault()
        if (!oldPassword || !newPassword || !repeatPassword) {
            setIsError(true)
            setErrorMessage(MISSING_FIELDS)
            return
        }

        if (newPassword !== repeatPassword) {
            setIsError(true)
            setErrorMessage(PASSWORDS_ARE_NOT_SAME)
            return
        }
        performPasswordChange()
    }

    if (isLoading) {
        return (
            <AdminLayout>
                <Row className="justify-content-center">
                    <Loader color="#00BFFF"/>
                </Row>
            </AdminLayout>
        )
    }

    return (
        <AdminLayout>
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <Form onSubmit={submitForm}>
                        <h3>Change password</h3>

                        <FormGroup role="form">
                            <Label>Old password *</Label>
                            <Input invalid={isError} type="password" name="old-pwd" value={oldPassword} onChange={e => oldPasswordChanged(e)} />
                        </FormGroup>

                        <FormGroup role="form">
                            <Label>New password *</Label>
                            <Input invalid={isError} type="password" name="new-pwd" value={newPassword} onChange={e => newPasswordChanged(e)} />
                        </FormGroup>

                        <FormGroup role="form">
                            <Label>New password again *</Label>
                            <Input invalid={isError} type="password" name="repeat-pwd" value={repeatPassword} onChange={e => repeatPasswordChanged(e)} />
                            { isError &&<FormFeedback>{errorMessage}</FormFeedback>}
                        </FormGroup>

                        <Button type="submit" color="primary" >Save</Button>
                    </Form>
                </div>
            </div>
        </AdminLayout>
    )
}

export default ChangePasswordPage
