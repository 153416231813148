import axios from "axios";

const buildHeaders = () => {
    let headers = {}

    const token = localStorage.getItem('token');
    if (token) {
        headers = { Authorization: 'Bearer ' + token }
    }
    return headers
}

export default axios.create({
    headers: buildHeaders(),
    timeout: 1000000,
});
