import React, {useEffect, useState} from "react"
import {useToasts} from "react-toast-notifications"

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Row
} from "reactstrap"

import API from "../../../utils/API";
import {apiConfig} from "../../../constants/api"
import Loader from "react-loader-spinner"


const allowedKeys = ['bot_id']

const FbPageEditModal = ({ pageToEdit, onSave, isOpen, setIsOpen }) => {
    const toggle = () => {
        setIsOpen((prevOpen) => { return !prevOpen })
    }

    const [page, setPage] = useState(pageToEdit)
    const [isLoading, setIsLoading] = useState(false)
    const [canBeAdded, setCanBeAdded] = useState(false)

    const {addToast} = useToasts()

    useEffect(() => {
        setPage(pageToEdit)
    }, [pageToEdit])

    const onFormChange = (changedPart) => {
        if (!allowedKeys.includes(changedPart)) {
            return () => {}
        }

        return (e) => {
            e.persist()

            setPage((prevPage) => {
                const newClient = {...prevPage}
                newClient[changedPart] = e.target.value

                setCanBeAdded(Boolean(newClient.bot_id))
                return newClient
            })
        }
    }

    const submitForm = (e) => {
        e.preventDefault()
        setIsLoading(true)
        API.post(apiConfig.url.v1.PAGES.UPDATE, page)
            .then((resp) => {
                addToast('Page edited', { appearance: 'success', autoDismiss: true })
                setCanBeAdded(false)
                if (onSave) {
                    onSave()
                }
                setIsOpen(false)
            }).catch((err) => {
            addToast('Error editing page. Try again', { appearance: 'error', autoDismiss: true })
        }).finally(() => {
            setIsLoading(false)
        })

    }

    if (!page) {
        return (<></>)
    }


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <Form onSubmit={submitForm}>
                <ModalHeader toggle={toggle}>Edit Page settings</ModalHeader>

                {isLoading &&
                <ModalBody>
                    <Row className="justify-content-center"><Loader color="#00BFFF"/></Row>
                </ModalBody>
                }

                {!isLoading &&
                <ModalBody>
                    <FormGroup role="form">
                        <Label>Bot ID *</Label>
                        <em>Ask you manager to get one</em>
                        <Input type="text" name="bot_id"
                               value={page.bot_id} onChange={onFormChange('bot_id')}/>
                    </FormGroup>
                </ModalBody>
                }

                <ModalFooter>
                    <Button disabled={isLoading || !canBeAdded} type="submit" color="primary">Save</Button>
                    <Button onClick={toggle} color="secondary">Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )

}

export default FbPageEditModal
