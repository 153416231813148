import React from "react";
import { Container } from "reactstrap";

import './admin-layout.css'

const AdminLayout = ({ children }) => {
    return (
        <Container fluid className="content border">
            {children}
        </Container>
    )
}

export default AdminLayout
